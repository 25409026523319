const config = {
  env: "local",


  // apiUrl: "http://localhost:5519/admin/",
   apiUrl: "https://api.findflats4sale.com/admin",

  shipRocketApiUrl: "https://apiv2.shiprocket.in/v1/external/",
};

export default config;
